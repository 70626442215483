import React, { useState, createContext, useContext } from "react";
import { LOCAL_STORAGE_KEYS } from "../constant";
import {jwtDecode, JwtPayload} from "jwt-decode";

interface AppContextInterface {
  auth: any;
  setAuth: React.Dispatch<any>;
  scope: string | null;
}
const authContext = createContext<AppContextInterface>(
  {} as AppContextInterface
);

export function ProvideAuth(props: any) {
  const { children } = props;
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.authToken);
  const [auth, setAuth] = useState(() => {
    
    if (authToken) {
      return true;
    }
    return false;
  });

  // Extract scope from authToken if available
  
  type customJwtPayload = JwtPayload & { scope: string };
  const scope = authToken && jwtDecode<customJwtPayload>(authToken).scope;
  
  
  return (
    <authContext.Provider value={{ auth, setAuth, scope }}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}
