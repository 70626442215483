import { FunctionComponent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import AlertMessage from "../../AlertMessage";
import { Formik } from "formik";
import { changeCoordinatorPassword, changePasswordSchema } from "./utils";

const AccountProfileDetails: FunctionComponent = (props) => {
  const [changePasswordResponse, setResponse] = useState<any>("");

  return (
    <Formik
      initialValues={{
        newPassword: "",
        confirmNewPassword: "",
      }}
      validationSchema={changePasswordSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        setResponse("");
        const response = await changeCoordinatorPassword(values);
        setResponse(response);
        setSubmitting(false);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate {...props}>
          <Card>
            <CardHeader title="Change password" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="New password"
                    name="newPassword"
                    required
                    variant="outlined"
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.newPassword}
                    type="password"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Confirm new password"
                    name="confirmNewPassword"
                    required
                    variant="outlined"
                    error={Boolean(
                      touched.confirmNewPassword && errors.confirmNewPassword
                    )}
                    helperText={
                      touched.confirmNewPassword && errors.confirmNewPassword
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmNewPassword}
                    type="password"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                disabled={isSubmitting}
                type="submit"
                color="primary"
                variant="contained"
              >
                Save details
              </Button>
            </Box>
            {changePasswordResponse ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  p: 2,
                }}
              >
                <AlertMessage
                  severity={changePasswordResponse.status}
                  message={changePasswordResponse.message}
                />
              </Box>
            ) : null}
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default AccountProfileDetails;
