import React, { FunctionComponent, ReactElement } from "react";
import { useRoutes } from "react-router-dom";
import GlobalStyles from "./components/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import routes from "./navigation/routes";
import { ProvideAuth } from "./navigation/ProvideAuth";
import * as Sentry from "@sentry/react";

const App: FunctionComponent = (): ReactElement => {
  const routing = useRoutes(routes);
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <ProvideAuth>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          {routing}
        </ThemeProvider>
      </ProvideAuth>
    </Sentry.ErrorBoundary>
  );
};

export default App;
