import { FunctionComponent, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { loginCoordinator, loginSchema } from "./utils";
import { LOCAL_STORAGE_KEYS } from "../../constant";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../navigation/ProvideAuth";

const Login: FunctionComponent = (): ReactElement => {
  const navigate = useNavigate();
  const [loginResponse, setLoginResponse] = useState<any>("");
  const { setAuth } = useAuth();

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);
              setLoginResponse("");
              const response = await loginCoordinator(values);
              setLoginResponse(response);
              if (response.status === "success" && response.authToken) {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.authToken,
                  response.authToken
                );
                setAuth(response.authToken);
                navigate("/app/members");
              }
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h4">
                    Sign in to Coordinator dashboard
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
                {loginResponse && loginResponse.status === "error" && (
                  <Alert severity="error">{loginResponse.message}</Alert>
                )}
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
