import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import * as utils from "./utils";
import AlertSnackBar from "../AlertSnackbar";
import { Formik } from "formik";
import { useAuth } from "../../navigation/ProvideAuth";


export const capitalizeFirstLetter = (word: string) => {
  const capitalized =
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  return capitalized;
};

const AddDistributionRule = (props: any) => {
  const { scope } = useAuth();
  const navigate = useNavigate(); // Hook for navigation
  const isAuthorized = scope === 'superAdmin' ? true: false;
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarData, setSnackBarData] = useState<any>();
  const [guardians, setGuardians] = useState<any>("");
  const [landscapes, setLandscapes] = useState<any>("");
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/unauthorized', { replace: true });
    }
  }, [isAuthorized, navigate]);


  useEffect(() => {
    (async () => {
      const responseLandscapes = await utils.getAllLandscapes();
      setLandscapes(responseLandscapes.data);
      const responseOnboardedGuardians = await utils.getAllOnboardedGuardians();
      setGuardians(responseOnboardedGuardians.data);
    })();
  }, []);

  const handleSubmitSaveDistribution = async (
    values: any,
    setSubmitting: any,
    resetForm: any
  ) => {
    const result = await utils.saveDistributionRuleForGuardian(
      { stripeConnectUserId: values.guardian, landscapeId: values.landscape },
      setSubmitting
    );
    if (result.status === "success") {
      const responseOnboardedGuardians = await utils.getAllOnboardedGuardians();
      setGuardians(responseOnboardedGuardians.data);
      resetForm();
    }
    setSnackBarData({
      severity: result.status,
      message: result.message,
    });
    setSnackBarOpen(true);
  };

  return (
    <>
    {isAuthorized && (
    <Formik
      initialValues={{
        guardian: "",
        landscape: "",
      }}
      validationSchema={utils.saveDistributionSchema}
      onSubmit={async (values, { resetForm }) => {
        handleSubmitSaveDistribution(values, setSubmitting, resetForm);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} autoComplete="off" noValidate {...props}>
          <Card>
            <CardHeader title="Add distribution rule for guardian" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-guardian">
                      Select guardian
                    </InputLabel>
                    <Select
                      id="select-guardian"
                      label="Select guardian"
                      name="guardian"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.guardian}
                      error={Boolean(touched.guardian && errors.guardian)}
                    >
                      {guardians
                        ? guardians.map((option: any) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.firstName} {option.lastName},{" "}
                              {option.role
                                ? capitalizeFirstLetter(option.role)
                                : ""}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="select-landscape">
                      Select landscape
                    </InputLabel>
                    <Select
                      id="select-landscape"
                      label="Select landscape"
                      name="landscape"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.landscape}
                      error={Boolean(touched.landscape && errors.landscape)}
                    >
                      {landscapes
                        ? landscapes.map((option: any) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                disabled={isSubmitting}
                type="submit"
                color="primary"
                variant="contained"
              >
                Save rule
              </Button>
            </Box>
          </Card>
          <AlertSnackBar
            open={snackBarOpen}
            severity={snackBarData?.severity}
            message={snackBarData?.message}
            handleClose={() => setSnackBarOpen(false)}
          />
        </form>
      )}
    </Formik>
    )}
    </>
  );
};

export default AddDistributionRule;
