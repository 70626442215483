import { useState, FunctionComponent, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import {
  getLandscapeDistribution,
  removeDistributionRuleForGuardian,
} from "./utils";
import CircularLoader from "../CircularLoader";
import { capitalizeFirstLetter } from "../guardian/AddDistributionRule";
import Button from "@mui/material/Button";
import AlertSnackBar from "../AlertSnackbar";

interface LandscapeDistributionProps {
  landscapeId: number;
  scope: string;
}

const LandscapeDistribution: FunctionComponent<LandscapeDistributionProps> = ({
  landscapeId,
  scope,
  ...rest
}) => {
  const [landscapeDistributionData, setLandscapeDistribution] =
    useState<any>("");
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarData, setSnackBarData] = useState<any>();
  const [submittingId, setSubmittingId] = useState<any>("");

  useEffect(() => {
    landscapeId &&
      (async () => {
        setLandscapeDistribution("");
        const response = await getLandscapeDistribution({ landscapeId });
        if (response.status === "success") {
          setLandscapeDistribution(response.data);
        } else {
          setSnackBarData(response);
          setSnackBarOpen(true);
        }
      })();
  }, [landscapeId]);

  const handleSubmitRemoveDistribution =
    (scuId: number, landscapeId: number, distributionId: number) =>
    async () => {
      const response = await removeDistributionRuleForGuardian(
        {
          stripeConnectUserId: scuId,
          distributionId,
          landscapeId,
        },
        setSubmittingId
      );
      if (response.status === "success") {
        setLandscapeDistribution("");
        const response = await getLandscapeDistribution({ landscapeId });
        if (response.status === "success") {
          setLandscapeDistribution(response.data);
        } else {
          setSnackBarData(response);
          setSnackBarOpen(true);
        }
      }
      setSnackBarData(response);
      setSnackBarOpen(true);
    };

  return landscapeDistributionData ? (
    <Card {...rest}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Name
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Account Type</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Role</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Phone</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Share percentage</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Hectares</TableCell>
                <TableCell sx={{ fontWeight: 600 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {landscapeDistributionData.map((distribution: any) => (
                <TableRow hover key={distribution.email}>
                  <TableCell>{`${capitalizeFirstLetter(
                    distribution.firstName
                  )} ${capitalizeFirstLetter(
                    distribution.lastName
                  )}`}</TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(distribution.accountType)}
                  </TableCell>
                  <TableCell>
                    {distribution.role &&
                      capitalizeFirstLetter(distribution.role)}
                  </TableCell>
                  <TableCell>{distribution.email}</TableCell>
                  <TableCell>{distribution.phoneNumber}</TableCell>
                  <TableCell>{distribution.sharePercentage}</TableCell>
                  <TableCell>{distribution.hectares}</TableCell>
                  <TableCell align="right">
                    {distribution.accountType === "guardian" && scope === 'superAdmin' && (
                      <Button
                        onClick={handleSubmitRemoveDistribution(
                          distribution.stripeConnectUserId,
                          distribution.landscapeId,
                          distribution.id
                        )}
                        disabled={submittingId === distribution.id}
                        variant="contained"
                      >
                        Remove
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <AlertSnackBar
        open={snackBarOpen}
        severity={snackBarData?.status}
        message={snackBarData?.message}
        handleClose={() => setSnackBarOpen(false)}
      />
    </Card>
  ) : (
    <CircularLoader />
  );
};

export default LandscapeDistribution;
