import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import AlertSnackBar from "./AlertSnackbar";
import { Card, CardHeader, Divider } from "@mui/material";
interface CopyLinkProps {
  link: string;
  setOpen: any;
  open: boolean;
}

const CopyLink: React.FunctionComponent<CopyLinkProps> = ({
  link,
  setOpen,
  open,
}) => {
  const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);
  const [snackBarData, setSnackBarData] = React.useState<any>();
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickCopyLink = () => {
    navigator.clipboard.writeText(link).then(
      function () {
        setSnackBarOpen(true);
        setSnackBarData({
          severity: "success",
          message: "Link copied to clipboard",
        });
      },
      function () {
        setSnackBarOpen(true);
        setSnackBarData({
          severity: "error",
          message: "Error in copying link",
        });
      }
    );
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Card>
          <CardHeader
            subheader="You can copy this onboarding link and share with the guardian to
              create their stripe account in order to receive their payouts."
            title="Stripe Connect onboarding link"
          />
          <Divider />
          <DialogContent>
            <TextField
              sx={{ marginTop: "15px" }}
              id="name"
              label="Onboarding link"
              type="email"
              fullWidth
              value={link}
              InputProps={{
                readOnly: true,
              }}
            />
          </DialogContent>
          <Divider />
          <DialogActions sx={{ margin: "10px 0" }}>
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              sx={{ marginRight: "15px" }}
              variant="contained"
              onClick={handleClickCopyLink}
            >
              Copy Link
            </Button>
          </DialogActions>
        </Card>
      </Dialog>
      <AlertSnackBar
        open={snackBarOpen}
        severity={snackBarData?.status}
        message={snackBarData?.message}
        handleClose={() => setSnackBarOpen(false)}
      />
    </div>
  );
};
export default CopyLink;
