import * as Yup from "yup";
import { API_BASE_URL } from "../../../config";
import axiosInstance from "../../../interceptor";

export interface ChangePasswordResponse {
  status: "success" | "error";
  message: string;
}

interface ChangePasswordData {
  newPassword: string;
  confirmNewPassword: string;
}

export const initialValues: ChangePasswordData = {
  newPassword: "",
  confirmNewPassword: "",
};

export const changePasswordSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, "Password should be minimum 8 characters")
    .required("Password is required"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

export const changeCoordinatorPassword = async (
  values: ChangePasswordData
): Promise<ChangePasswordResponse> => {
  try {
    const url = API_BASE_URL + "/coordinator/change-password";
    const payload = {
      newPassword: values.newPassword,
    };
    await axiosInstance.post(url, payload);
    return {
      status: "success",
      message: "Password changes successfully",
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};
