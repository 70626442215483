import { LOCAL_STORAGE_KEYS } from "../../constant";
import axiosInstance from "../../interceptor";

export const logoutUser = async () => {
  try {
    await axiosInstance.post("/auth/logout");
    localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
    return {
      status: "success",
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};
