import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";

interface PartnerListProps {
  setStatus: any;
  status: any;
  scope: string;
}

const items = [
  {
    id: "1",
    optionText: "Registered",
    value: "accountCreated",
  },
  {
    id: "2",
    optionText: "Stripe onboarded",
    value: "onboarded",
  },
];

const PartnerList: FunctionComponent<PartnerListProps> = ({
  status,
  setStatus,
  scope,
  ...rest
}) => {
  const navigate = useNavigate();
  return (
    <Box {...rest}>
      { scope === 'superAdmin' && 
      <Box
        sx={{
          marginBottom: "15px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={() => navigate("/app/partners/add-distribution")}>
          Add distribution rule
        </Button>
      </Box>
      }
      <Card>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Partners</Typography>
          <Box sx={{ width: "200px" }}>
            <FormControl fullWidth>
              <InputLabel id="choose-partner-account-status">
                Choose account status
              </InputLabel>
              <Select
                id="choose-partner-account-status"
                label="Choose account status"
                name="accountStatus"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                {items.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PartnerList;
