import { useState, FunctionComponent, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { getPartnersByStatus, generateOnboardingLink } from "./utils";
import CircularLoader from "../CircularLoader";
import Button from "@mui/material/Button";
import AlertSnackBar from "../AlertSnackbar";
import CopyLink from "../CopyLink";

interface PartnerListResultsProps {
  status: "accountCreated" | "onboarded";
  scope: string;
}

export const joinArrayOfStrings = (arr: string[]) => {
  return arr
    .filter(Boolean)
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join(",");
};

const PartnerListResults: FunctionComponent<PartnerListResultsProps> = ({
  status,
  scope,
  ...rest
}) => {
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [customerData, setCustomerData] = useState<any>("");
  const [count, setCount] = useState<any>();
  const [onboardingLinkSubmittingId, setOnboardingLinkSubmittingId] =
    useState<any>("");
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarData, setSnackBarData] = useState<any>();
  const [linkDialogOpen, setLinkDialogOpen] = useState<boolean>(false);
  const [onboardingLink, seOnboardingLink] = useState<string>("");

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSubmitGenerateOnboardingLink =
    (accountRoleId: number) => async () => {
      const response = await generateOnboardingLink(
        { accountRoleId },
        setOnboardingLinkSubmittingId
      );
      if (response.status === "success") {
        seOnboardingLink(response.link);
        setLinkDialogOpen(true);
      } else {
        setSnackBarData(response);
        setSnackBarOpen(true);
      }
    };

  useEffect(() => {
    (async () => {
      setCustomerData("");
      const { data } = await getPartnersByStatus({
        limit,
        page: page + 1,
        status,
      });
      setCustomerData(data.data);
      setCount(data.count);
    })();
  }, [limit, page, status]);

  return customerData ? (
    <Card {...rest}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  First name
                </TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Last name
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                <TableCell
                  sx={{ fontWeight: 600 }}
                >{`Registered Role(s)`}</TableCell>
                <TableCell sx={{ fontWeight: 600 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData.map((customer: any) => (
                <TableRow hover key={customer.email}>
                  <TableCell>{customer.firstName}</TableCell>
                  <TableCell>{customer.lastName}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>
                    {customer.roles ? joinArrayOfStrings(customer.roles) : ""}
                  </TableCell>
                  <TableCell align="right">
                    {status === "accountCreated" && scope === 'superAdmin' ? (
                      <Button
                        onClick={handleSubmitGenerateOnboardingLink(
                          customer.accountRoleId
                        )}
                        disabled={
                          onboardingLinkSubmittingId === customer.accountRoleId
                        }
                        variant="contained"
                      >
                        Generate link
                      </Button>
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 15]}
      />
      <AlertSnackBar
        open={snackBarOpen}
        severity={snackBarData?.status}
        message={snackBarData?.message}
        handleClose={() => setSnackBarOpen(false)}
      />
      <CopyLink
        link={onboardingLink}
        open={linkDialogOpen}
        setOpen={setLinkDialogOpen}
      />
    </Card>
  ) : (
    <CircularLoader />
  );
};

export default PartnerListResults;
