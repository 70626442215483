import { FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import { Menu, Input } from "@mui/icons-material";
import Logo from "../Logo";
import { logoutUser } from "./utils";
import { useNavigate } from "react-router-dom";

interface DashboardNavbarProps {
  onMobileNavOpen(): void;
}

const DashboardNavbar: FunctionComponent<DashboardNavbarProps> = ({
  onMobileNavOpen,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleClickLogout = async () => {
    const result = await logoutUser();
    if (result.status === "success") {
      navigate("/login");
    }
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <Menu />
          </IconButton>
        </Hidden>
        <IconButton onClick={handleClickLogout} color="inherit">
          <Input />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
