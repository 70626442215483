import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import PartnerListResults from "../components/partner/PartnerListResults";
import { useState } from "react";
import PartnerListToolbar from "../components/partner/PartnerListToolbar";
import { useAuth } from "../navigation/ProvideAuth";

const PartnersList = () => {
  const { scope } = useAuth();
  const [status, setStatus] = useState<any>("accountCreated");

  return (
    <>
      <Helmet>
        <title>Partners</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <PartnerListToolbar status={status} setStatus={setStatus} scope={scope ?? ""}/>
          <Box sx={{ pt: 3 }}>
            <PartnerListResults status={status} scope={scope ?? ""}/>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PartnersList;
