import React, { FunctionComponent } from "react";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface AlertMessageProps {
  severity: "success" | "error";
  message: string;
}

const AlertMessage: FunctionComponent<AlertMessageProps> = ({
  severity,
  message,
}) => {
  return (
    <Alert
      icon={
        severity === "success" ? (
          <CheckIcon fontSize="inherit" />
        ) : (
          <ErrorOutlineIcon fontSize="inherit" />
        )
      }
      severity={severity}
    >
      {message}
    </Alert>
  );
};

export default AlertMessage;
