import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import CustomerListResults from "../components/customer/CustomerListResults";
import CustomerListToolbar from "../components/customer/CustomerListToolbar";
import { useState } from "react";

const CustomerList = () => {
  const [months, setMonths] = useState<any>("");

  return (
    <>
      <Helmet>
        <title>Members</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <CustomerListToolbar months={months} setMonths={setMonths} />
          <Box sx={{ pt: 3 }}>
            <CustomerListResults months={months} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CustomerList;
