import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import AccountProfileDetails from "../components/account/ChangePassword/ChangePassword";

const Account = () => (
  <>
    <Helmet>
      <title>Account</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth="lg">
        <AccountProfileDetails />
      </Container>
    </Box>
  </>
);

export default Account;
