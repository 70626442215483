import { FunctionComponent } from "react";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { People } from "@mui/icons-material";

const TotalMembers: FunctionComponent = () => (
  <Card
    sx={{
      width: "266px",
    }}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "266px",
        }}
      >
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="subtitle2">
            TOTAL MEMBERS
          </Typography>
          <Typography color="textPrimary" variant="h4">
            1,600
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: green[600],
              height: 56,
              width: 56,
            }}
          >
            <People />
          </Avatar>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

export default TotalMembers;
