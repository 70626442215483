import { API_BASE_URL } from "../../config";
import axiosInstance from "../../interceptor";
import * as Yup from "yup";

export interface GetGuardiansDataResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

interface GetGuardiansDataReqData {
  limit: number;
  page: number;
  status: "interested" | "accountActivated" | "onboarded";
}

export const getGuardiansByStatus = async (
  values: GetGuardiansDataReqData
): Promise<GetGuardiansDataResponse> => {
  try {
    const url =
      API_BASE_URL +
      `/guardian?status=${values.status}&limit=${values.limit}&page=${values.page}`;
    const data = await axiosInstance.get(url);
    return {
      status: "success",
      message: "Password changes successfully",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
      data: undefined,
    };
  }
};

export interface ActivateGuardianAccountReqData {
  interestId: number;
  hectares: number;
}

export interface GenerateOnboardingLinkReqData {
  accountRoleId: number;
}

export interface GenerateOnboardingLinkResponse {
  status: "success" | "error";
  message: string;
  link: string;
}

export interface ActivateGuardianAccountResponse {
  status: "success" | "error";
  message: string;
}

export const activateGuardianAccount = async (
  values: ActivateGuardianAccountReqData,
  setAccountActSubmittingId: any,
  setSubmitting: any
): Promise<ActivateGuardianAccountResponse> => {
  try {
    setSubmitting(true);
    const url = API_BASE_URL + `/activate-customer-account`;
    await axiosInstance.post(url, values);
    return {
      status: "success",
      message: "Account activated successfully",
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchActivationErrors(err?.response?.data?.error) ||
        "Something went wrong. Please try again later.",
    };
  } finally {
    setSubmitting(false);
    setAccountActSubmittingId("");
  }
};

const matchActivationErrors = (errorCode: string): string => {
  if (errorCode === "accountAlreadyActivated") {
    return "Account is already activated. Please check once.";
  } else if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};

const matchOnboardingLinkErrors = (errorCode: string): string => {
  console.log("errorcode", errorCode)
  if (errorCode === "accountRoleDoesNotExist") {
    return "Account is not activated. Please activate account first.";
  } else if (errorCode === "alreadyConnectOnboarded") {
    return "Member is already onboarded on connect.";
  } else if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};

export const generateOnboardingLink = async (
  values: GenerateOnboardingLinkReqData,
  setSubmitting: any
): Promise<GenerateOnboardingLinkResponse> => {
  try {
    setSubmitting(values.accountRoleId);
    const url = API_BASE_URL + `/generate-onboarding-link`;
    const data: any = await axiosInstance.post(url, values);
    return {
      status: "success",
      message: "Account activated successfully",
      link: data.link,
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchOnboardingLinkErrors(err?.response?.data?.error) ||
        "Something went wrong. Please try again later.",
      link: "",
    };
  } finally {
    setSubmitting("");
  }
};

interface GetAllLandscapesResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

export const getAllLandscapes = async (): Promise<GetAllLandscapesResponse> => {
  try {
    const url = API_BASE_URL + `/landscape/all`;
    const data: any = await axiosInstance.get(url);
    return {
      status: "success",
      message: "",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
      data: "",
    };
  }
};

interface GetAllOnboardedGuardiansResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

export const getAllOnboardedGuardians =
  async (): Promise<GetAllOnboardedGuardiansResponse> => {
    try {
      const url = API_BASE_URL + `/guardians/onboarded`;
      const data: any = await axiosInstance.get(url);
      return {
        status: "success",
        message: "",
        data,
      };
    } catch (err: any) {
      return {
        status: "error",
        message: "Something went wrong. Please try again later.",
        data: "",
      };
    }
  };
export interface SaveDistributionRuleReqData {
  stripeConnectUserId: any;
  landscapeId: any;
}

const matchDistributionRuleErrors = (errorCode: string): string => {
  if (errorCode === "invalidLandScapeId") {
    return "Invalid landscape Id";
  } else if (errorCode === "invalidStripeConnectUserId") {
    return "Invalid user id";
  } else if (errorCode === "guardianIsNotConnectOnboarded") {
    return "Guardian is not onboarded on connect";
  } else if (errorCode === "alreadySixGuardians") {
    return "Maximum six guardians are allowed for a landscape";
  } else if (errorCode === "distributionRuleAlreadyExist") {
    return "Distribution rule already exist for the guardian";
  } else if (errorCode === "userRoleDoesNotExistsForGuardian") {
    return "Role doesn't exists for guardian. Please add guardian role first.";
  } else if (errorCode === "hectareDoesNotExistsForGuardian") {
    return "Hectare information doesn't exists for guardian. Please add hectares first.";
  } else if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};

export const saveDistributionRuleForGuardian = async (
  values: SaveDistributionRuleReqData,
  setSubmitting: any
): Promise<GetAllOnboardedGuardiansResponse> => {
  try {
    setSubmitting(true);
    const url = API_BASE_URL + `/guardian/add-distribution-rule`;
    const data: any = await axiosInstance.post(url, values);
    return {
      status: "success",
      message: "Distribution saved",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchDistributionRuleErrors(err?.response?.data?.error) ||
        "Something went wrong. Please try again later.",
      data: "",
    };
  } finally {
    setSubmitting(false);
  }
};

export const saveDistributionSchema = Yup.object({
  guardian: Yup.number().required("Required"),
  landscape: Yup.number().required("Required"),
});

export const getAllGuardians = async (): Promise<GetAllLandscapesResponse> => {
  try {
    const url = API_BASE_URL + `/guardians/all`;
    const data: any = await axiosInstance.get(url);
    return {
      status: "success",
      message: "",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
      data: "",
    };
  }
};

export const saveGuardianShapefileSchema = Yup.object({
  guardian: Yup.number().required("Required"),
  landscape: Yup.number().required("Required"),
  file: Yup.mixed()
    .required('A geojson file is required')
    .test('fileSize', 'File is too large', (value) => value === null || (value && value.size <= 1048576 * 2)),
});

export interface SaveShapefileData {
  userRoleId: any;
  landscapeId: any;
  file: any;
}

interface GetGuardiansResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

const matchGuardianShapefileErrors = (errorCode: string): string => {
  
  if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};


export const saveGuardianShapefile = async (
  values: SaveShapefileData,
  setSubmitting: any
): Promise<GetGuardiansResponse> => {
  try {
    setSubmitting(true);
    const url = API_BASE_URL + `/guardian/upload-shapefile`;
    const data: any = await axiosInstance.post(url, values, {
      headers : {
        Accept: "multipart/form-data",
        "Content-Type": "multipart/form-data",
      }
    });
    return {
      status: "success",
      message: "Shapefile saved",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: matchGuardianShapefileErrors(err?.response?.data?.errors) ||
        "Something went wrong. Please try again later.",
      data: "",
    };
  } finally {
    setSubmitting(false);
  }
};