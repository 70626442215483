import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { FunctionComponent } from "react";

interface CustomerListToolbarProps {
  setMonths: any;
  months: any;
}

const items = [
  {
    id: "1",
    optionText: "Last 1 month",
    value: 1,
  },
  {
    id: "2",
    optionText: "Last 2 month",
    value: 2,
  },
  {
    id: "3",
    optionText: "Last 3 month",
    value: 3,
  },
  {
    id: "4",
    optionText: "All",
    value: "All",
  },
];

const CustomerListToolbar: FunctionComponent<CustomerListToolbarProps> = ({
  months,
  setMonths,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Card>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Members</Typography>
          <Box sx={{ width: "200px" }}>
            <FormControl fullWidth>
              <InputLabel id="select-time-period">
                Choose time period
              </InputLabel>
              <Select
                id="select-time-period"
                label="Choose time period"
                name="timePeriod"
                onChange={(e) => setMonths(e.target.value)}
                value={months}
              >
                {items.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.optionText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomerListToolbar;
