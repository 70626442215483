import { API_BASE_URL } from "../../config";
import axiosInstance from "../../interceptor";

export interface GetLandscapeDistributionResponse {
  status: "success" | "error";
  data?: any;
  message?: string;
}

export interface GetLandscapesResponse {
  status: "success" | "error";
  data?: any;
  message?: string;
}

interface LandscapeDistributionReqData {
  landscapeId: number;
}

export const getLandscapeDistribution = async (
  values: LandscapeDistributionReqData
): Promise<GetLandscapeDistributionResponse> => {
  try {
    const url =
      API_BASE_URL + `/landscape/distribution-rule/${values.landscapeId}`;
    const data = await axiosInstance.get(url);
    return {
      status: "success",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};

interface RemoveDistributionRuleReqData {
  stripeConnectUserId: number;
  landscapeId: number;
  distributionId: number;
}

interface RemoveDistributionResponse {
  status: "success" | "error";
  message?: string;
}
const matchRemoveDistributionRuleErrors = (errorCode: string): string => {
  if (errorCode === "guardianDistributionDoesNotExist") {
    return "Distribution rule doesn't exist for the guardian";
  } else if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};

export const removeDistributionRuleForGuardian = async (
  values: RemoveDistributionRuleReqData,
  setSubmitting: any
): Promise<RemoveDistributionResponse> => {
  try {
    setSubmitting(values.distributionId);
    const url = API_BASE_URL + `/guardian/remove-distribution-rule`;
    await axiosInstance.put(url, {
      landscapeId: values.landscapeId,
      stripeConnectUserId: values.stripeConnectUserId,
    });
    return {
      status: "success",
      message: "Distribution removed successfully",
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchRemoveDistributionRuleErrors(err?.response?.data?.error) ||
        "Something went wrong. Please try again later.",
    };
  } finally {
    setSubmitting("");
  }
};
