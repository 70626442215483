import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

export default function PrivateRoute({ children }: { children: any }) {
  const location = useLocation();
  const { auth } = useAuth();
  if (!auth) {
    return <Navigate to={"/login"} state={{ from: location }} replace />;
  }
  return children;
}
