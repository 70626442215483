import { FunctionComponent, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
} from "@mui/material";
import { Users as UsersIcon } from "react-feather";
import {
  ManageAccounts,
  NaturePeople,
  Forest,
  Group,
} from "@mui/icons-material";
import NavItem from "../NavItem";
import { getCurrentCoordinatorDetails, getTitleFromRole } from "./utils";

const items = [
  {
    href: "/app/members",
    icon: <UsersIcon size={20} />,
    title: "Members",
  },
  {
    href: "/app/guardians",
    icon: <NaturePeople />,
    title: "Guardians",
  },
  {
    href: "/app/partners",
    icon: <Group />,
    title: "Partners",
  },
  {
    href: "/app/landscape",
    icon: <Forest />,
    title: "Landscapes",
  },
  {
    href: "/app/account",
    icon: <ManageAccounts />,
    title: "Account",
  },
];

interface DashboardSidebarProps {
  onMobileClose(): void;
  openMobile: boolean;
}

const DashboardSidebar: FunctionComponent<DashboardSidebarProps> = ({
  onMobileClose,
  openMobile,
}) => {
  const [user, setUser] = useState<any>("");
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={"/static/images/avatars/avatar_6.png"}
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64,
          }}
          to="/app/account"
        />
        <Typography color="textPrimary" variant="h5">
          {user.firstName + " " + user.lastName}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {getTitleFromRole(user.role)}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  useEffect(() => {
    (async () => {
      const response = await getCurrentCoordinatorDetails();
      if (response.status === "success") {
        setUser(response.data);
      }
    })();
  }, []);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
