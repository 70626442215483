import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import GuardianListResults from "../components/guardian/GuardianListResults";
import GuardianListToolbar from "../components/guardian/GuardianListToolbar";
import { useState } from "react";
import { useAuth } from "../navigation/ProvideAuth";

const MembersList = () => {
  const { scope } = useAuth();

  const [status, setStatus] = useState<any>("interested");

  return (
    <>
      <Helmet>
        <title>Guardians</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <GuardianListToolbar status={status} setStatus={setStatus} scope={scope ?? ""}/>
          <Box sx={{ pt: 3 }}>
            <GuardianListResults status={status} scope={scope ?? ""}/>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MembersList;
