import * as Yup from "yup";
import axios from "axios";
import { API_BASE_URL } from "../../config";

export interface LoginResponse {
  status: "success" | "error";
  message: string;
  authToken?: string;
}

interface LoginFormData {
  email: string;
  password: string;
}

export const initialValues: LoginFormData = {
  email: "",
  password: "",
};

export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is Required"),
  password: Yup.string().required("Password is required"),
});

const matchRegistrationErrors = (errorCode: string): string => {
  if (errorCode === "userDoesNotExist") {
    return "Account doesn't exist. Please contact support.";
  } else if (errorCode === "incorrectPassword") {
    return "Incorrect credentials";
  } else {
    return "";
  }
};

export const loginCoordinator = async (
  values: LoginFormData
): Promise<LoginResponse> => {
  try {
    const url = API_BASE_URL + "/coordinator/login";
    const payload = {
      email: values.email,
      password: values.password,
    };
    const { data } = await axios.post(url, payload);
    return {
      status: "success",
      message: "",
      authToken: data.authToken,
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchRegistrationErrors(err?.response?.data?.errors) ||
        "Something went wrong. Please try again later.",
    };
  }
};
