import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { getAllLandscapes } from "../guardian/utils";
import AlertSnackBar from "../AlertSnackbar";

interface LandscapeToolbarProps {
  setLandscapeId: any;
  landscapeId: any;
}

const LandscapeToolbar: FunctionComponent<LandscapeToolbarProps> = ({
  landscapeId,
  setLandscapeId,
  ...rest
}) => {
  const [landscapes, setLandscapes] = useState<any>("");
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarData, setSnackBarData] = useState<any>();

  useEffect(() => {
    (async () => {
      const response = await getAllLandscapes();
      if (response.status === "success") {
        setLandscapes(response.data);
        setLandscapeId(response.data[0].id);
      } else {
        setSnackBarData(response);
        setSnackBarOpen(true);
      }
    })();
  }, [setLandscapeId]);

  return (
    <Box {...rest}>
      <Card>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">Landscapes</Typography>
          <Box sx={{ width: "200px" }}>
            <FormControl fullWidth>
              <InputLabel id="choose-landscape">Choose landscape</InputLabel>
              <Select
                id="choose-landscape"
                label="Choose landscape"
                name="landscape"
                onChange={(e) => setLandscapeId(e.target.value)}
                value={landscapeId}
              >
                {landscapes
                  ? landscapes.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
        <AlertSnackBar
          open={snackBarOpen}
          severity={snackBarData?.status}
          message={snackBarData?.message}
          handleClose={() => setSnackBarOpen(false)}
        />
      </Card>
    </Box>
  );
};

export default LandscapeToolbar;
