import { Helmet } from "react-helmet";
import { Box, Container } from "@mui/material";
import { useState } from "react";
import LandscapeDistribution from "../components/landscape/LandscapeDistribution";
import LandscapeToolbar from "../components/landscape/LandscapeToolbar";
import { useAuth } from "../navigation/ProvideAuth";

const Landscape = () => {
  const { scope } = useAuth();
  const [landscapeId, setLandscapeId] = useState<any>("");

  return (
    <>
      <Helmet>
        <title>Landscapes</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
        
          <LandscapeToolbar
            landscapeId={landscapeId}
            setLandscapeId={setLandscapeId}
          />
      
          <Box sx={{ pt: 3 }}>
            <LandscapeDistribution landscapeId={landscapeId} scope={scope ?? ""}/>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Landscape;
