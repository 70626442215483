import { API_BASE_URL } from "../../config";
import axiosInstance from "../../interceptor";

export interface GetCustomerDataResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

interface GetCustomerDataReqData {
  limit: number;
  page: number;
  months: number | undefined | string;
}

export const getCustomerData = async (
  values: GetCustomerDataReqData
): Promise<GetCustomerDataResponse> => {
  try {
    let url;
    if (!values.months || values.months === "All") {
      url =
        API_BASE_URL +
        `/coordinator/customer-data?limit=${values.limit}&page=${values.page}`;
    } else {
      url =
        API_BASE_URL +
        `/coordinator/customer-data?limit=${values.limit}&page=${values.page}&lastMonths=${values.months}`;
    }
    const data = await axiosInstance.get(url);
    return {
      status: "success",
      message: "Password changes successfully",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
      data: undefined,
    };
  }
};
