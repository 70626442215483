import { useState, FunctionComponent, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { getCustomerData } from "./utils";
import CircularLoader from "../CircularLoader";
import { capitalizeFirstLetter } from "../guardian/AddDistributionRule";

interface CustomerListResultsProps {
  months: number;
}

const CustomerListResults: FunctionComponent<CustomerListResultsProps> = ({
  months,
  ...rest
}) => {
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [customerData, setCustomerData] = useState<any>("");
  const [count, setCount] = useState<any>();

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    (async () => {
      const { data } = await getCustomerData({
        limit,
        page: page + 1,
        months,
      });
      setCustomerData(data.data);
      setCount(data.count);
    })();
  }, [limit, page, months]);

  return customerData ? (
    <Card {...rest}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600 }}>UserId</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>AccountId</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Signup</TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  First name
                </TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Last name
                </TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Display name
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Phone</TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Account type
                </TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Role
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Plan</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Landscape</TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Subscription created
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Currency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData.map((customer: any) => (
                <TableRow hover key={customer.id}>
                  <TableCell>{customer.id}</TableCell>
                  <TableCell>{customer.accountId}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {new Date(customer.signup).toISOString().slice(0, 10)}
                  </TableCell>
                  <TableCell>{customer.firstName}</TableCell>
                  <TableCell>{customer.lastName}</TableCell>
                  <TableCell>{customer.displayName}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.phoneNumber}</TableCell>
                  <TableCell>
                    {capitalizeFirstLetter(customer.accountType)}
                  </TableCell>
                  <TableCell>
                    {customer.role ? capitalizeFirstLetter(customer.role) : ""}
                  </TableCell>
                  <TableCell>{customer.plan}</TableCell>
                  <TableCell sx={{ whiteSpace: "nowrap" }}>
                    {customer.landscape}
                  </TableCell>
                  <TableCell>
                    {customer.subscriptionCreated
                      ? new Date(customer.subscriptionCreated)
                          .toISOString()
                          .slice(0, 10)
                      : null}
                  </TableCell>
                  <TableCell>{customer.currency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 15]}
      />
    </Card>
  ) : (
    <CircularLoader />
  );
};

export default CustomerListResults;
