import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from "@mui/material";
import * as utils from "./utils";
import AlertSnackBar from "../AlertSnackbar";
import { Formik } from "formik";
import { useAuth } from "../../navigation/ProvideAuth";


const AddShapefile = (props: any) => {
    const { scope } = useAuth();
    const navigate = useNavigate(); // Hook for navigation
    const isAuthorized = scope === 'superAdmin' ? true: false;
    const [guardians, setGuardians] = useState<any>("");
    const [landscapes, setLandscapes] = useState<any>("");
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const [snackBarData, setSnackBarData] = useState<any>();
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const handleSubmitSaveShapefile = async (
        values: any,
        setSubmitting: any,
        resetForm: any
        ) => {
            const result = await utils.saveGuardianShapefile(
                { userRoleId: values.guardian, landscapeId: values.landscape, file: values.file },
                setSubmitting
              );
            if (result.status === "success") {
                resetForm();
            }
            setSnackBarData({
                severity: result.status,
                message: result.message,
            });
            setSnackBarOpen(true);
    };

    useEffect(() => {
        if (!isAuthorized) {
          navigate('/unauthorized', { replace: true });
        }
      }, [isAuthorized, navigate]);

    useEffect(() => {
        (async () => {
            const responseLandscapes = await utils.getAllLandscapes();
            setLandscapes(responseLandscapes.data);
            const responseGuardians = await utils.getAllGuardians();
            setGuardians(responseGuardians.data);
        })();
    }, []);

    return (
        <>
        {isAuthorized && (
        <Formik
            initialValues={{
                guardian: '',
                landscape: '',
                file: null,
            }}
            validationSchema={utils.saveGuardianShapefileSchema}
            onSubmit={async (values, { resetForm }) => {
                handleSubmitSaveShapefile(values, setSubmitting, resetForm);
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                touched,
                values,
            }) => (
                <form onSubmit={handleSubmit} autoComplete='off' noValidate {...props}>
                    <Card>
                    <CardHeader title="Add shapefile for a guardian" />
                    <Divider />
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="select guardian">
                                        Select Guardian
                                    </InputLabel>
                                    <Select
                                        id="select-guardian"
                                        label="Select Guardian"
                                        name="guardian"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.guardian}
                                        error={Boolean(touched.guardian && errors.guardian)}
                                    >
                                        {guardians 
                                            ? guardians.map((option: any) => (
                                                <MenuItem key={option.accountId} value={option.accountId}>
                                                    {option.firstName} {option.lastName} ({option.email})
                                                </MenuItem>
                                            )) : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="select-landscape">
                                    Select landscape
                                    </InputLabel>
                                    <Select
                                    id="select-landscape"
                                    label="Select landscape"
                                    name="landscape"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.landscape}
                                    error={Boolean(touched.landscape && errors.landscape)}
                                    >
                                        {landscapes
                                            ? landscapes.map((option: any) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FormControl fullWidth> 
                                    <input id="file" name="file" type="file" accept='.geojson' onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFieldValue("file", event.currentTarget.files ? event.currentTarget.files[0] : '');
                                    }} className="form-control" />  
                                    <FormHelperText id="geojason-type">Upload .geojson type file only.</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2,
                    }}
                    >
                        <Button
                            disabled={isSubmitting}
                            type="submit"
                            color="primary"
                            variant="contained"
                        >
                            Save shapefile
                        </Button>
                    </Box>
                    </Card>
                    <AlertSnackBar
                        open={snackBarOpen}
                        severity={snackBarData?.severity}
                        message={snackBarData?.message}
                        handleClose={() => setSnackBarOpen(false)}
                    />
                </form>
            )}
        </Formik>
        )}
        </>
    );
};

export default AddShapefile;