import { Navigate } from "react-router-dom";
import DashboardLayout from "../components/DashboardLayout";
import MainLayout from "../components/MainLayout";
import Dashboard from "../pages/Dashboard";
import CustomerList from "../pages/CustomerList";
import GuardiansList from "../pages/GuardiansList";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Account from "../pages/Account";
import PrivateRoute from "./PrivateRoute";
import AddDistributionRule from "../components/guardian/AddDistributionRule";
import AddPartnerDistributionRule from "../components/partner/AddDistributionRule";
import AddShapefile from "../components/guardian/AddShapefile";
import Landscape from "../pages/Landscape";
import PartnersList from "../pages/PartnersList";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "members",
        element: (
          <PrivateRoute>
            <CustomerList />
          </PrivateRoute>
        ),
      },
      {
        path: "guardians",
        element: (
          <PrivateRoute>
            <GuardiansList />
          </PrivateRoute>
        ),
      },
      {
        path: "account",
        element: (
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        ),
      },
      {
        path: "guardians/add-distribution",
        element: (
          <PrivateRoute>
            <AddDistributionRule />
          </PrivateRoute>
        ),
      },
      {
        path: "partners/add-distribution",
        element: (
          <PrivateRoute>
            <AddPartnerDistributionRule />
          </PrivateRoute>
        ),
      },

      {
        path: "guardians/add-shapefile",
        element: (
          <PrivateRoute>
            <AddShapefile />
          </PrivateRoute>
        ),
      },

      {
        path: "landscape",
        element: (
          <PrivateRoute>
            <Landscape />
          </PrivateRoute>
        ),
      },
      {
        path: "partners",
        element: (
          <PrivateRoute>
            <PartnersList />
          </PrivateRoute>
        ),
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" /> },
      { path: "/", element: <Navigate to="/app/members" /> },
    ],
  },
];

export default routes;
