import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface HectareInputProps {
  handleClose: any;
  open: boolean;
  onSubmit: any;
  actor: string;
}

const HectareInput: React.FunctionComponent<HectareInputProps> = ({
  handleClose,
  open,
  onSubmit,
  actor,
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [hectaresValue, setHectaresValue] = useState("");

  const handleSubmit = async () => {
    await onSubmit(setSubmitting, hectaresValue);
    setHectaresValue("");
    handleClose();
  };

  const handleChange = (e: any) => {
    setHectaresValue(e.target.value);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Area</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter area taken care by the {actor} in hectares.
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="name"
            label="Hectares"
            type="number"
            fullWidth
            variant="outlined"
            onChange={handleChange}
            value={hectaresValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={isSubmitting === true || hectaresValue === ""}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HectareInput;
