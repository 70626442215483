import { API_BASE_URL } from "../../config";
import axiosInstance from "../../interceptor";
import * as Yup from "yup";

export interface GetGuardiansDataResponse {
  status: "success" | "error";
  data?: any;
  message?: string;
}

interface GetGuardiansDataReqData {
  limit: number;
  page: number;
  status: "accountCreated" | "onboarded";
}

export const getPartnersByStatus = async (
  values: GetGuardiansDataReqData
): Promise<GetGuardiansDataResponse> => {
  try {
    const url =
      API_BASE_URL +
      `/partner?status=${values.status}&limit=${values.limit}&page=${values.page}`;
    const data = await axiosInstance.get(url);
    return {
      status: "success",
      data: data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};

export interface GenerateOnboardingLinkReqData {
  accountRoleId: number;
}

export interface GenerateOnboardingLinkResponse {
  status: "success" | "error";
  message: string;
  link: string;
}

const matchOnboardingLinkErrors = (errorCode: string): string => {
  if (errorCode === "accountRoleDoesNotExist") {
    return "Account is not activated. Please activate account first.";
  } else if (errorCode === "alreadyConnectOnboarded") {
    return "Member is already onboarded on connect.";
  } else if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};

export const generateOnboardingLink = async (
  values: GenerateOnboardingLinkReqData,
  setSubmitting: any
): Promise<GenerateOnboardingLinkResponse> => {
  try {
    setSubmitting(values.accountRoleId);
    const url = API_BASE_URL + `/generate-onboarding-link`;
    const data: any = await axiosInstance.post(url, values);
    return {
      status: "success",
      message: "Account activated successfully",
      link: data.link,
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchOnboardingLinkErrors(err?.response?.data?.error) ||
        "Something went wrong. Please try again later.",
      link: "",
    };
  } finally {
    setSubmitting("");
  }
};

interface GetAllLandscapesResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

export const getAllLandscapes = async (): Promise<GetAllLandscapesResponse> => {
  try {
    const url = API_BASE_URL + `/landscape/all`;
    const data: any = await axiosInstance.get(url);
    return {
      status: "success",
      message: "",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
      data: "",
    };
  }
};

interface getAllPartnersQualifiedForLdResponse {
  status: "success" | "error";
  data: any;
  message: string;
}

export const getAllPartnersQualifiedForLd = async (
  landscapeId: number
): Promise<getAllPartnersQualifiedForLdResponse> => {
  try {
    const url = API_BASE_URL + `/partner/${landscapeId}`;
    const data: any = await axiosInstance.get(url);
    return {
      status: "success",
      message: "",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
      data: "",
    };
  }
};
export interface SaveDistributionRuleReqData {
  landscapeId: any;
  userRoleId: number;
  hectares: number;
}

const matchDistributionRuleErrors = (errorCode: string): string => {
  if (errorCode === "invalidLandScapeId") {
    return "Invalid landscape Id";
  } else if (errorCode === "invalidStripeConnectUserId") {
    return "Invalid user id";
  } else if (errorCode === "partnerIsNotConnectOnboarded") {
    return "Partner is not onboarded on connect";
  } else if (errorCode === "distributionRuleAlreadyExist") {
    return "Distribution rule already exist for the partner";
  } else if (errorCode === "userRoleDoesNotExist") {
    return "Role does not exist for the partner";
  } else if (errorCode === "Forbidden") {
    return "You are not authorized to perform this action.";
  } else {
    return errorCode;
  }
};

export const saveDistributionRuleForPartner = async (
  values: SaveDistributionRuleReqData,
  setSubmitting: any
): Promise<getAllPartnersQualifiedForLdResponse> => {
  try {
    setSubmitting(true);
    const url = API_BASE_URL + `/partner/add-distribution-rule`;
    const data: any = await axiosInstance.post(url, values);
    return {
      status: "success",
      message: "Distribution saved",
      data,
    };
  } catch (err: any) {
    return {
      status: "error",
      message:
        matchDistributionRuleErrors(err?.response?.data?.error) ||
        "Something went wrong. Please try again later.",
      data: "",
    };
  } finally {
    setSubmitting(false);
  }
};

export const saveDistributionSchema = Yup.object({
  partner: Yup.number().required("Required"),
  landscape: Yup.number().required("Required"),
  hectares: Yup.number().required("Required"),
});
