import axios from "axios";
import { API_BASE_URL } from "./config";
import { LOCAL_STORAGE_KEYS } from "./constant";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function (config: any) {
  const token = `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEYS.authToken)}`;
  config.headers.Authorization = token;
  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
