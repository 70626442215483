import axiosInstance from "../../interceptor";

export const getCurrentCoordinatorDetails = async () => {
  try {
    const data: any = await axiosInstance.get("/coordinator");
    return {
      status: "success",
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        role: data.role,
      },
    };
  } catch (err: any) {
    return {
      status: "error",
      message: "Something went wrong. Please try again later.",
    };
  }
};

export const getTitleFromRole = (role: string) => {
  if (role === "superAdmin") {
    return "Super Admin";
  } else if (role === "admin") {
    return "Admin";
  } else {
    return "";
  }
};
